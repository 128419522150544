






















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import IconStarToggler from '@/_modules/icons/components/icon-star-toggler.vue';

@Component({
  components: {
    IconStarToggler
  }
})
export default class CompanyCard extends Vue {
  @Prop(Object) readonly company: TPromoPage;

  onShowCompany (): void {
    this.$emit('onShowCompany');
  }

  onToggleFavorite (): void {
    this.$emit('onToggleFavorite');
  }
}
