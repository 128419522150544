<template>
  <div
    class="companies-main-wrapper"
    :class="{'companies': isViewTypeList}"
  >
    <template v-if="isViewTypeList">
      <mu-circular-progress
        v-if="isLoading"
        class="demo-circular-progress"
        :size="36"
      />
      <div
        v-else-if="isEmpty"
        class="text-center"
      >
        <div class="no-companies-found-on-request">
          <div class="text">
            {{ $t('title.no_companies_found') }}
          </div>
          <div class="subtext">
            {{ $t('title.try_to_change_search_request') }}
          </div>
        </div>
      </div>
      <div
        class="companies-wrapper"
        :class="{
          'column': flexDirection === 'column',
          'row': flexDirection === 'row',
          'flex-rowwrap': flexDirection === 'flex-rowwrap',
          'companies-wrapper-fixed' : flexDirection === 'column',
        }"
      >
        <div
          :class="[flexDirection, {'scroll-height': flexDirection === 'column'}]"
          ref="scrollerCompanies"
        >
          <company-card
            v-for="(company, key) in defineListSource"
            :key="key"
            :company="company"
            :ref="`companyBadge_${company.external_id}`"
            @onToggleFavorite="toggleFavorite(company)"
            @onShowCompany="showCompany(company.external_id)"
          />
          <infinite-loading
            v-if="defineInfiniteHandler"
            class="companies-loader"
            @infinite="onInfiniteHandler"
          />
        </div>
      </div>
      <router-view
        class="two-cols-wrapper"
        name="promoHallCompany"
      />
    </template>
    <companies-map v-else/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import CompaniesMap from '@/_modules/promo-hall/components/companies-map';
import CompanyCard from '@/_modules/promo-hall/components/company-card';
import broadcastsService from '@/_services/broadcasts.service';
import { BroadcastType } from "@/_types/broadcasts/broadcast-type.enum";

export default {
  name: 'promo-companies',
  components: {
    CompanyCard,
    CompaniesMap,
    InfiniteLoading
  },
  computed: {
    ...mapGetters({
      isLoading: 'promoPageStore/isLoading',
      isViewTypeList: 'promoPageStore/isViewTypeList',
      isListTypeAll: 'promoPageStore/isListTypeAll',
      isListTypeMy: 'promoPageStore/isListTypeMy',
      getListAll: 'promoPageStore/getListAll',
      isListAllMore: 'promoPageStore/isListAllMore',
      getListMy: 'promoPageStore/getListMy',
      isListMyMore: 'promoPageStore/isListMyMore',
      isFilters: 'promoPageStore/isFilters',
      isPavilions: 'promoPageStore/isPavilions',
      getListAllFilteredByPavilion: 'promoPageStore/getListAllFilteredByPavilion'
    }),
    isEmpty() {
      let empty = false;
      if (this.isListTypeAll) {
        empty = this.getListAll.length === 0;
      } else if (this.isListTypeMy) {
        empty = this.getListMy.length === 0;
      }
      return empty;
    },
    defineListSource() {
      return this.isListTypeAll ? this.getListAll : this.getListMy;
    },
    defineInfiniteHandler() {
      return this.isListTypeAll ? this.isListAllMore : this.isListMyMore;
    },
    flexDirection() {
      return this.$route.params.external_id ? 'column' : 'row'
    },
  },
  data() {
    return {
      customOffset: 0,
      findBroadCastInterval: null,
      promoPagesInterval: null,
      activeBroadcasts: []
    }
  },
  mounted() {
    if (this.$route.params.external_id) {
      if (this.defineListSource.length === 0) {
        this.onInfiniteHandler();
      }
      this.scrollOnTopByExternalId();
    }

    this.findBroadCastInterval = setInterval(() => {
      for (let i = 0; i < this.activeBroadcasts.length; i++) {
        // let ref = this.$refs['companyBadge_' + this.activeBroadcasts[i].details.promopage.external_id]
        let nodeId = document.getElementById(`companyBadge_${this.activeBroadcasts[i].details.promopage.external_id}`)

        if (nodeId) {
          nodeId.classList.remove('active-broadcast');
        }
      }
      this.activeBroadcasts = broadcastsService.getActiveBroadcasts()

      for (let i = 0; i < this.activeBroadcasts.length; i++) {
        // let ref = this.$refs['companyBadge_' + this.activeBroadcasts[i].details.promopage.external_id]
        let nodeId = document.getElementById(`companyBadge_${this.activeBroadcasts[i].details.promopage.external_id}`)
        if (nodeId) {
          if(this.activeBroadcasts[i].type === BroadcastType.PROMO_CONTACT) {
            nodeId.classList.add('active-broadcast');
          }
        }
      }
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.findBroadCastInterval);
    clearInterval(this.promoPagesInterval);
  },
  methods: {
    ...mapActions({
      promoPageListAll: 'promoStore/promoPageListAll',
      promoPageListMy: 'promoStore/promoPageListMy',
      setListAll: 'promoPageStore/setListAll',
      setListMy: 'promoPageStore/setListMy',
      addFavorite: 'promoPageStore/addFavorite',
      removeFavorite: 'promoPageStore/removeFavorite',
    }),

    async showCompany(external_id) {

      await this.$store.dispatch('promoStore/getPromoPage', {
        event_id: this.$route.params.eventId,
        external_id,
      });
      await this.$router.push({
        name: 'promo-page-events-company', params: {
          eventId: this.$route.params.eventId,
          external_id,
        }
      }).catch(() => {
        /* ignore */
      });

      this.scrollOnTopByExternalId(external_id);
    },

    toggleFavorite(company) {
      const payload = {
        event_id: this.$route.params.eventId,
        external_id: company.external_id
      };

      company.is_favorite ? this.removeFavorite(payload) : this.addFavorite(payload);

      if (company.external_id === this.$route.params.external_id) {
        this.$store.dispatch('promoStore/getPromoPage', payload);
      }
    },

    scrollOnTopByExternalId(externalId) {
      if (typeof externalId == 'undefined') {
        externalId = this.$route.params.external_id;
      }
      const chosen = this.$refs['companyBadge_' + externalId];
      if ((typeof externalId == 'undefined') || !chosen) {
        setTimeout(this.scrollOnTopByExternalId, 333);
        return;
      }
      this.$refs.scrollerCompanies.scrollTo({
        top: chosen[0].$el.offsetTop - chosen[0].$el.offsetHeight / 2,
        left: 0,
        behavior: 'smooth'
      });
    },

    scrollOnTop(event) {
      const scroller = this.$refs.scrollerCompanies.$el;
      const halfHeight = 70;
      const difference = Math.max(0, event.target.offsetTop - halfHeight) - scroller.scrollTop;
      let count = 10; // no more than 60 timeouts
      const step = difference / 10; // pixel amount
      const stepper = function () {
        scroller.scrollTop += step;
        count--;
        if (count > 0) {
          setTimeout(stepper, 11);
        }
      };
      setTimeout(stepper, 11);
      return false; // Stopping from passing the event into further scroll handlers
    },

    async onInfiniteHandler ($state) {
      if (this.isListTypeAll) {
        await this.setListAll();
        if($state){
          $state.loaded();
        }
      } else if (this.isListTypeMy) {
        await this.setListMy();
        if($state){
          $state.loaded();
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.companies-main-wrapper {
  width: 100%;
  min-width: $promo-page-content-min-width;
}

// AW-734
.two-cols-wrapper {
  width: calc(100% - 400px); // 100% - ширина колонки со списком - паддинги страницы левый правый - ширина сайдбара
  min-width: 800px;
}

.scroll-height {
  height: calc(100vh - 207px);
  position: relative;
  // width: 442px; // Subtract 22 if removing hover compensation (ctrl+F AW-1335)
}

.head-company-nav {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0;
  color: #b5b5b5;
  position: relative;
  padding: 8px 0 8px 10px;
}

.companies {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  &-loader {
    width: 100%;
  }

  /* TODO: fix bad classname choice — .row gets unwanted styling from Muse UI
   * this rule removes unwanted styling from Muse UI
   */
  > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.companies-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  border-radius: 25px;
  padding-bottom: 30px;
  height: auto;
  z-index: 1;
}

.chosen {
  border-style: solid;
  border-width: 2px;
  border-color: $ew-blue;

  .fav {
    display: none;
  }
}

.head-nav {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0;
  color: #b5b5b5;
  padding-left: 38px;
  padding-top: 28px;
  padding-bottom: 10px;
}

.flex-rowwrap {
  flex-flow: row wrap;
}

.column {
  flex-flow: column wrap;
}

.company {
  width: 400px;
  height: 130px;
  background: $ew-white 0 0 no-repeat padding-box;
  box-shadow: $ew-box-shadow-edge-contrast;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 23px;
  margin: 0 8px 10px;
  cursor: pointer;

  &:hover {
    box-shadow: $ew-box-shadow-hover;
  }

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    opacity: 1;

    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .content {
    padding-left: 11px;
    align-self: flex-start;
    max-width: 280px;
    line-height: 1.1;
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    .title {
      text-align: left;
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 400;
      letter-spacing: 0;
      color: $ew-black;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-style: normal;
      word-break: break-word;
    }

    .subtitle {
      text-align: left;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: 400;
      letter-spacing: 0;
      color: $ew-black;
      word-break: break-word;
      opacity: 0.4;
      margin: 0.3rem 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-style: normal;
      width: 100%;
    }

    .subtitle.location {
      opacity: 1;
      margin: 0;
      margin-top: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      line-clamp: 1;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-style: normal;
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.4rem;
      word-break: break-word;
    }

    .info {
      text-align: left;
      font-size: 8px;
      letter-spacing: 0;
      color: $ew-black;
    }
  }

  .fav {
    margin-left: auto;
  }
}

/* AW-415 */
.companies-wrapper-fixed {
  padding-left: 402px;
  padding-top: 0;
  position: relative;
  margin-right: 2px;

  .column {
    position: fixed;
    margin-left: -434px; // subtract 22 if hover compensation is not needed
    padding-left: 22px; // AW-1335, hover compensation.
    padding-bottom: 100px;
    height: calc(100vh - 160px);
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      position: relative;
      z-index: 1;
      border-radius: 3px;
      background: #aaa;
    }

    .company {
      transition: transform 0.2s, border-radius 0.2s;
    }
  }
}

/* AW-415 END --------- */

.no-events {
  padding: 34px;
  margin: auto;
}

.demo-circular-progress {
  margin-left: auto;
  margin-right: auto;
}

.inner-loading-circle {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  margin: 0 auto;
}

.no-companies-found-on-request {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.168824px;
  color: black;

  .text {
    font-weight: 600;
  }

  .subtext {
    opacity: 0.5;
  }
}

/deep/ .active-broadcast {
  .live-broadcast {
    display: flex;
  }
}
</style>
